import { BasicSelect, ModelSelect } from 'vue-search-select';
import commonHelper from '@/app/utility/common.helper.utility';
import appStrings from '@/app/utility/string.utility';
import DatePicker from 'vue2-datepicker';
import PaymentInvoice from './paymentInvoice';
import store from '../../../../store';
export default {
  name: 'PaymentAdvice',
  components: {
    BasicSelect,
    ModelSelect,
    DatePicker,
    PaymentInvoice
  },
  watch: {
    perPage: function() {
      this.currentPage = 1, 
      this.getPaymentAdviceList();
    },
    currentPage: function() {
      this.getPaymentAdviceList();
    }
  },
  data() {
    return {
      unsubscribe: null,
      perPage: commonHelper.perPageRecord,
      pageOptions: commonHelper.getPageOption(),
      totalRows: null,
      showPaymentInvoiceDtls: false,
      loader: false,
      currentPage: 1,
      selectLegalEntity: {
        text: null,
        value: null
      },
      leList: [],
      selectOuName: {
        text: null,
        value: null
      },
      ouList: [],
      bankName: null,
      bankAcc: null,
      fromCheckDate: null,
      toCheckDate: null,
      chequeFromDateTodate: null,
      supplierNum: null,
      supplierName: null,
      checkId: null,
      checkNum: null,
      paymentAdviceRowDtl: null,
      paymentAdviceData: [],
      paymentAdviceFields: [
        {
          key: 'LE_NAME',
          label: 'LEGAL ENTITY'
        },
        {
          key: 'OU_NAME',
          label: 'OPERATING UNIT'
        },
        {
          key: 'CHECK_ID'
        },
        {
          key: 'PAYMENT_DATE'
        },
        {
          key: 'VENDOR_NO',
          label: 'SUPPLIER NUM'
        },
        {
          key: 'CURRENCY_CODE',
          label: 'CURRENCY'
        },
        {
          key: 'AMOUNT'
        },
        {
          key: 'MAIL_SENT',
          label: 'EMAIL STATUS'
        },
        {
          key: 'BANK_NAME'
        },
        {
          key: 'BANK_AC_NUM'
        }
      ]
    };
  },
  mounted() {
    this.getOracleLeList();
  },
  methods: {
    getOracleLeList() {
      const payload = {
        valueSetName: appStrings.VALUESETTYPE.LEGAL_ENTITY_LIST,
        dependentVariable: {}
      };
      payload.dependentVariable.user_id = store.state.auth.userId;
      payload.dependentVariable.res_id = store.state.shared.responsibilityId;
      this.$store
        .dispatch('shared/getCommonVSetData', payload)
        .then(response => {
          if (response.status === 200) {
            const results = response.data.data.data;
            const leagalEntity = results.map(type => {
              return {
                value: type.org_id,
                text: type.value_code
              };
            });
            this.leList = leagalEntity;
            this.selectedLegalEntity =
              leagalEntity && leagalEntity.length === 1
                ? leagalEntity[0]
                : this.selectedLegalEntity;
          }
        });
    },
    getOuByLegalId(event) {
      this.selectLegalEntity = event;
      this.getOracleOuList(this.selectLegalEntity.value);
    },
    getOracleOuList(leId) {
      const payload = {
        valueSetName: appStrings.VALUESETTYPE.OU_LIST,
        dependentVariable: {}
      };
      payload.dependentVariable.le_id = leId;
      this.$store
        .dispatch('shared/getCommonVSetData', payload)
        .then(response => {
          const results = response.data.data.data;
          this.ouList = results.map(item => {
            return {
              value: item.org_id,
              text: item.value_code
            };
          });
        });
    },
    getPaymentAdviceList() {
      this.payload = {
        _page: this.currentPage - 1,
        _limit: this.perPage,
        le_id: this.selectLegalEntity.value,
        ou_id: this.selectOuName.value,
        bank_name: this.bankName,
        bank_ac: this.bankAcc,
        check_id: this.checkId,
        check_num: this.checkNum,
        from_date: this.fromCheckDate,
        to_date: this.toCheckDate,
        sup_no: this.supplierNum,
        sup_name: this.supplierName
      };
      this.loader = true;
      this.$store
        .dispatch('oracleApi/getPaymentAdviceList', this.payload)
        .then(response => {
          this.loader = false;
          if (response.status === 200) {
            this.paymentAdviceData = response.data.data.page;
            this.totalRows = response.data.data.total_elements;
          }
        })
        .catch(() => {
          this.loader = false;
        });
    },
    rowSelected(item) {
      this.paymentAdviceRowDtl = item;
      this.showHidePaymentInvoiceModal(true);
    },
    showHidePaymentInvoiceModal(flag) {
      this.showPaymentInvoiceDtls = flag;
    },
    clearFilters() {
      this.selectLegalEntity = {
        text: null,
        value: null
      };
      this.selectOuName = {
          text: null,
          value: null
        };
      this.bankName = null;
        this.bankAcc = null;
        this.fromCheckDate = null;
        this.toCheckDate = null;
        this.supplierNum = null;
        this.supplierName = null;
        this.checkId = null;
        this.checkNum = null;
        this.ouList = [];
        this.chequeFromDateTodate = [];
        this.paymentAdviceData = [];
    }
  },
  beforeDestroy() {}
};
