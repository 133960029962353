import commonHelper from '@/app/utility/common.helper.utility';
export default {
  name: 'PaymentInvoice',
  props: ['paymentAdviceRowDtl'],
  watch: {
    perPage: function() {
      this.currentPage = 1, 
      this.getPaymentInvoiceDetails();
    },
    currentPage: function() {
      this.getPaymentInvoiceDetails();
    }
  },
  data() {
    return {
      perPage: commonHelper.perPageRecord,
      pageOptions: commonHelper.getPageOption(),
      totalRows: null,
      loader: false,
      currentPage: 1,
      isSuccess: false,
      showAlert: false,
      responseMsg: '',
      checkID: null,
      checkNum: null,
      partyName: null,
      currency: null,
      bankAcc: null,
      bankName: null,
      paymentHdrId: null,
      paymentInvoiceData: [],
      paymentInvoiceFields: [
        {
          key: 'INVOICE_NUM'
        },
        {
          key: 'VOUCHER_NUMBER'
        },
        {
          key: 'INVOICE_DATE'
        },
        {
          key: 'GL_DATE'
        },
        {
          key: 'INVOICE_TYPE'
        },
        {
          key: 'INVOICE_SOURCE'
        },
        {
          key: 'INVOICE_AMOUNT'
        },
        {
          key: 'AMOUNT_PAID'
        },
        {
          key: 'BALANCE_AMOUNT'
        },
        {
          key: 'AMT_PAID_IN_CHECK'
        },
        {
          key: 'INV_DESCRIPTION'
        }
      ]
    };
  },
  mounted() {
    if (this.paymentAdviceRowDtl) {
      this.checkID = this.paymentAdviceRowDtl.CHECK_ID;
      this.checkNum = this.paymentAdviceRowDtl.CHECK_NUMBER;
      this.partyName = this.paymentAdviceRowDtl.VENDOR_NAME;
      this.currency = this.paymentAdviceRowDtl.CURRENCY_CODE;
      this.bankName = this.paymentAdviceRowDtl.BANK_NAME;
      this.bankAcc = this.paymentAdviceRowDtl.BANK_AC_NUM;
      this.paymentHdrId = this.paymentAdviceRowDtl.PAYMNT_HDR_ID;
      this.getPaymentInvoiceDetails();
    }
  },
  methods: {
    getPaymentInvoiceDetails() {
      const payload = {
        _page: this.currentPage - 1,
        _limit: this.perPage,
        pmt_hdr_id: this.paymentHdrId
      };
      this.loader = true;
      this.$store
        .dispatch('oracleApi/getPaymentInvoiceList', payload)
        .then(response => {
          this.loader = false;
          if (response.status === 200) {
            this.paymentInvoiceData = response.data.data.page;
            this.totalRows = response.data.data.total_elements;
          }
        })
        .catch(() => {
          this.loader = false;
        });
    },

    truncateString(str) {
      const num = 35;
      if (str.length > num) {
        return str.slice(0, num) + '...';
      } else {
        return str;
      }
    }
  }
};
